import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import i18n from 'i18n-js';
import { useGetRecurDropdown, type ApolloClientLib } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import moment from 'moment-timezone';
import {
  calendar_util,
  formatSelectedDate,
} from 'client-lib/src/lib/utils/helpers';
import SelectTime from '../../../../../elements/Select/SelectTime';
import {
  Heading5,
  Select,
  SelectDate,
  Text,
  Card,
} from '../../../../../elements';
import THEMES from '../../../../../styles/themes/app';

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  height: 90%;
`;

const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const CalendarWrapper = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

const SelectTimeContainer = styled.div`
  width: 100%;
  #select-container {
    width: 406px;
  }
  #select-container > div {
    width: 100%;
  }
`;

const RecurContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RecurOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RecurOnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 50px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  padding-top: 16px;
`;

const EndOnDateContainer = styled.div`
  padding-bottom: 18px;
`;

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export interface ScheduleComponentViewProps {
  handleDateChange: (state: string) => void;
  selectedDate: Date;
  selectedHour: number;
  selectedMin: number;
  handleTimeChange: (value: string, updatedValueName: string) => void;
  deliveryError: string;
  locale: string;
  isMilitaryTime: (locale: string) => boolean;
  setRecurOption: (state: string) => void;
  recurOption: string;
  setEndsAtTime: (state: string) => void;
  endsAtTime: Date;
  setRecurOptionSelected: (state: boolean) => void;
  recurOptionSelected: boolean;
}

interface EnumProps {
  name: string;
}

interface SelectedProps {
  value: string;
}

interface OptionProps {
  label: string;
  value: string;
}

const order = ['NEVER', 'DAILY', 'WEEKLY', 'MONTHLY'];

const ScheduleComponent = ({
  handleDateChange,
  selectedDate,
  selectedHour,
  selectedMin,
  handleTimeChange,
  deliveryError,
  locale,
  isMilitaryTime,
  setRecurOption,
  recurOption,
  setEndsAtTime,
  endsAtTime,
  setRecurOptionSelected,
  recurOptionSelected,
}: ScheduleComponentViewProps) => {
  const client = useApolloClient() as unknown as ApolloClientLib;
  const styledTheme = useContext(ThemeContext);
  const { data } = useGetRecurDropdown({ client, name: 'RecurringBtmOption' });
  const formattedSelectedDate = new Date(`${selectedDate}\n`);
  const formattedEndAtDate = new Date(`${endsAtTime}\n`);
  const selecteDateValue = formatSelectedDate(formattedSelectedDate);
  const endDateValue = formatSelectedDate(formattedEndAtDate);
  const options = [
    {
      value: 'NEVER',
      label: i18n.t('settings-SharingSettings-never', {
        defaultValue: 'Never',
      }),
    },
  ];
  data?.__type?.enumValues.forEach((cur: EnumProps) => {
    const formattedName = i18n.t(`broadcasts-recurring-${cur?.name}`, {
      defaultValue: cur?.name,
    });
    options.push({ label: formattedName, value: cur.name });
  });
  const handleSelect = (e: SelectedProps) => {
    setRecurOption(e?.value);
  };

  const localePrefix = locale.substr(0, 2);

  const totalTime = selectedHour * 100 + selectedMin;
  const displayTime = moment(totalTime, 'hmm').format('hh:mm A');
  const displayDate = formattedSelectedDate.toLocaleString(
    localePrefix,
    dateOptions
  );

  const selectedDayOfMonth = formattedSelectedDate.getDate();

  return (
    <DeliveryContainer>
      <CalendarWrapper>
        <SelectDate
          label={i18n.t('broadcasts-recurring-sendOn', {
            defaultValue: 'send on',
          })}
          value={selecteDateValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleDateChange(e?.target?.value);
            if (e.target?.value > endDateValue) {
              setEndsAtTime(e.target.value);
            }
          }}
          hideBottomSpace
          calendarProps={{
            locale: localePrefix,
          }}
        />
      </CalendarWrapper>
      <SelectTimeContainer>
        <SelectTime
          label={i18n.t('slideouts-GroupMessageOverview-deliveryTime')}
          hourValue={selectedHour}
          minValue={selectedMin}
          onChange={handleTimeChange}
          error={deliveryError}
          isMilitaryTime={isMilitaryTime(locale.replace('_', '-'))}
        />
      </SelectTimeContainer>
      <Select
        label={i18n.t('broadcasts-recurring-repeats', {
          defaultValue: 'Repeats',
        })}
        options={options.sort((a: OptionProps, b: OptionProps) => {
          return (
            order.findIndex((x) => x === a.value) -
            order.findIndex((x) => x === b.value)
          );
        })}
        onChange={handleSelect}
        value={recurOption}
        data-testId="broadcast-recurring-repeats"
        isSearchable={false}
      />
      {recurOption !== 'NEVER' ? (
        <RecurContainer>
          <Heading5 customStyle={() => `font-size: 12px;`}>
            {i18n.t('broadcasts-recurring-end', { defaultValue: 'Ends' })}
          </Heading5>
          <DropdownContainer>
            <input
              style={{
                accentColor: THEMES.FOREGROUND_HIGH_COLOR({
                  theme: styledTheme,
                }),
              }}
              type="radio"
              checked={recurOptionSelected === false}
              onChange={() => {
                setRecurOptionSelected(false);
              }}
            />
            <Text>
              {i18n.t('settings-SharingSettings-never', {
                defaultValue: 'Never',
              })}
            </Text>
          </DropdownContainer>
          <RecurOptionsContainer>
            <RecurOnContainer>
              <input
                checked={recurOptionSelected === true}
                onChange={() => {
                  setRecurOptionSelected(true);
                }}
                style={{
                  accentColor: THEMES.FOREGROUND_HIGH_COLOR({
                    theme: styledTheme,
                  }),
                }}
                data-testId="broadcast-recurring-on"
                type="radio"
              />
              <Text>
                {i18n.t('broadcasts-recurring-on', { defaultValue: 'On' })}
              </Text>
            </RecurOnContainer>
            <EndOnDateContainer>
              <SelectDate
                customCalendarStyle={() => `
              left: -90px;`}
                disabled={recurOptionSelected !== true}
                value={endDateValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEndsAtTime(e.target.value);
                }}
                calendarProps={{
                  minDate:
                    calendar_util.dateYYYYMMDDtoDateObj(selecteDateValue),
                  locale: localePrefix,
                }}
                hideBottomSpace
              />
            </EndOnDateContainer>
          </RecurOptionsContainer>
          <div>
            <Heading5
              customStyle={() => `font-size: 12px; padding-bottom: 8px;`}
            >
              {i18n.t('broadcasts-recurring-summary', {
                defaultValue: 'Summary',
              })}
            </Heading5>
            <Card type="quaternary">
              {recurOption === 'WEEKLY' && (
                <Text>
                  {i18n.t('broadcasts-recurring-weekly', {
                    time: displayTime,
                    date: displayDate,
                  })}
                </Text>
              )}
              {recurOption === 'DAILY' && (
                <Text>
                  {i18n.t('broadcasts-recurring-daily', {
                    time: displayTime,
                    date: displayDate,
                  })}
                </Text>
              )}
              {recurOption === 'MONTHLY' && (
                <Text>
                  {i18n.t('broadcasts-recurring-monthly', {
                    time: displayTime,
                    date: displayDate,
                    day: selectedDayOfMonth,
                  })}
                  {selectedDayOfMonth >= 28 && <span>*</span>}
                </Text>
              )}
            </Card>
            {recurOption === 'MONTHLY' && selectedDayOfMonth >= 28 && (
              <div style={{ paddingTop: '8px' }}>
                <Text>* {i18n.t('broadcasts-reucurring-monthlyWarning')}</Text>
              </div>
            )}
          </div>
        </RecurContainer>
      ) : null}
    </DeliveryContainer>
  );
};

export default ScheduleComponent;
