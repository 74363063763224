import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  type Dispatch,
} from 'react';
import {
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  type LexicalEditor,
  type CommandListenerPriority,
  type BaseSelection,
} from 'lexical';
import {
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
  $isAutoLinkNode,
  $createLinkNode,
} from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import styled from 'styled-components';
import getSelectedNode from '../utils/getSelectedNode';
import richTextEditorLinkAttributes from '../shared/constants';

const LinkEditorWrapper = styled.div`
  .link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;

    .link-input {
      display: flex;
      width: calc(100% - 24px);
      box-sizing: border-box;
      margin: 8px 12px;
      padding: 8px 12px;
      border-radius: 15px;
      background-color: #eee;
      font-size: 15px;
      color: rgb(5, 5, 5);
      border: 0;
      outline: 0;
      position: relative;
      font-family: inherit;
      justify-content: space-between;
    }

    .link-edit-button {
      cursor: pointer;
      border: none;
    }

    a {
      color: rgb(33, 111, 219);
      text-decoration: none;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 30px;
      text-overflow: ellipsis;
    }

    .link-input a:hover {
      text-decoration: underline;
    }
  }
`;

function positionEditorElement(
  editorElem: HTMLElement,
  rect: DOMRect | null
): void {
  const editor = editorElem;
  const additionalOffset = 120;

  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left +
      window.pageXOffset -
      editor.offsetWidth / 2 +
      rect.width / 2 +
      additionalOffset
    }px`;
  }
}

interface FloatingLinkEditorProps {
  editor: LexicalEditor;
  priority: CommandListenerPriority;
  isLinkEditMode: boolean;
  setLinkEditMode: Dispatch<boolean>;
}

const FloatingLinkEditor = ({
  editor,
  priority,
  isLinkEditMode,
  setLinkEditMode,
}: FloatingLinkEditorProps) => {
  const editorRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(
    null
  );

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const { activeElement } = document;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      rootElement !== null &&
      nativeSelection?.anchorNode &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setLinkEditMode(false);
      setLinkUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        priority
      )
    );
  }, [editor, updateLinkEditor, priority]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isLinkEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLinkEditMode]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (lastSelection !== null) {
        if (linkUrl !== '') {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
          // AutoLinks are LinkNodes that get created automatically when a
          // user types something that looks like a link.  These are slightly
          // different than LinkNodes and need to updated specifically.
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              const parent = getSelectedNode(selection).getParent();
              if ($isAutoLinkNode(parent)) {
                const linkNode = $createLinkNode(parent.getURL(), {
                  rel: parent.__rel,
                  target: parent.__target,
                  title: parent.__title,
                });
                parent.replace(linkNode, true);
              }
            }
          });
        }
        setLinkEditMode(false);
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setLinkEditMode(false);
    }
  };

  return (
    <LinkEditorWrapper data-testid="rich-text-editor-floating-link-editor">
      <div ref={editorRef} className="link-editor">
        {isLinkEditMode ? (
          <input
            ref={inputRef}
            className="link-input"
            value={linkUrl}
            onChange={(event) => {
              setLinkUrl(event.target.value);
            }}
            onKeyDown={handleKeyDown}
            data-testid="rich-text-editor-floating-link-editor-input"
          />
        ) : (
          <div className="link-input">
            <a
              data-testid="rich-text-editor-floating-link-editor-anchor"
              href={linkUrl}
              target={richTextEditorLinkAttributes.target}
              rel={richTextEditorLinkAttributes.rel}
            >
              {linkUrl}
            </a>
            <button
              aria-label="Edit Link"
              className="link-edit-button ri-pencil-fill"
              type="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setLinkEditMode(true);
              }}
              data-testid="rich-text-editor-floating-link-editor-button"
            />
          </div>
        )}
      </div>
    </LinkEditorWrapper>
  );
};

export default FloatingLinkEditor;
