import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import Error from './components/Error/index.tsx';

class GlobalErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error) {
    Sentry.withScope((scope) => {
      scope.setLevel('fatal');
    });
    Sentry.captureException(`fatal error: ${error}`);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <Error />;
    }
    return this.props.children;
  }
}

GlobalErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GlobalErrorBoundary;
