import React from 'react';
import { useLocation } from 'react-router-dom';

import Error from '../Error';

const NewJitNoauth = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryParamValue = searchParams.getAll('missing_fields');
  const hasMissingFields = queryParamValue.length > 0;

  const formatList = (items: string[]): string => {
    const stringDefault = 'identity values';

    if (!items || !Array.isArray(items)) {
      return stringDefault;
    }

    const filteredItems = items?.filter((item) => item);

    if (filteredItems.length === 0) {
      return stringDefault;
    }

    if (filteredItems.length === 1 && filteredItems[0]) {
      return filteredItems[0];
    }

    if (filteredItems.length === 2) return filteredItems.join(' and ');

    return `${filteredItems.slice(0, -1).join(', ')}, and ${filteredItems[filteredItems.length - 1]}`;
  };

  if (hasMissingFields) {
    return (
      <Error
        translatedHeader={`It appears that there is an error with your ${formatList(queryParamValue)}.`}
        translatedMessage="Please reach out to your manager or IT admin to double check your permissions."
      />
    );
  }

  return <Error />;
};

export default NewJitNoauth;
