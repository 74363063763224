import React from 'react';
import i18n from 'i18n-js';
import styled, { ThemeProvider } from 'styled-components';

import ExternalScreenTemplate from '../../containers/Login/ExternalScreenTemplate';
import { Anchor, Heading1, Heading3, InputLabel } from '../../elements';
import { PROKEEP_KNOWLEDGE_CENTER_LINK } from '../../utils/helpers/constants/index';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;

const Content = styled.div`
  margin-bottom: 1em;
`;

const InputLabelWrapper = styled(InputLabel)`
  font-size: 16px;
  margin-right: 10px;
`;

const AnchorWrapper = styled(Anchor)`
  font-size: 16px;
`;

interface ErrorProps {
  translatedHeader?: string;
  translatedMessage?: string;
  phone?: string;
  email?: string;
  helpLink?: string;
  themeMode?: 'light' | 'dark';
  fixedFontSize?: boolean;
}

const Error = ({
  translatedHeader = i18n.t('signin-GlobalErrorBoundary-error'),
  translatedMessage = i18n.t('signin-GlobalErrorBoundary-refresh'),
  phone = '1-504-226-7756',
  email = 'support@prokeep.com',
  helpLink = PROKEEP_KNOWLEDGE_CENTER_LINK,
  themeMode = 'light',
  fixedFontSize = true,
}: ErrorProps) => {
  return (
    <ThemeProvider theme={{ mode: themeMode }}>
      <ExternalScreenTemplate screenType="maintenance">
        <ErrorWrapper>
          <SectionWrapper>
            <Heading1
              customStyle={fixedFontSize ? () => 'font-size: 2em;' : undefined}
            >
              {translatedHeader}
            </Heading1>
          </SectionWrapper>
          <SectionWrapper>
            <Heading3
              customStyle={
                fixedFontSize ? () => 'font-size: 1.17em;' : undefined
              }
            >
              {translatedMessage}
            </Heading3>
          </SectionWrapper>
          <SectionWrapper>
            <Content>
              <InputLabelWrapper>
                {i18n.t('signin-GlobalErrorBoundary-phone')}
              </InputLabelWrapper>
              <AnchorWrapper href={`tel:${phone}`}>{phone}</AnchorWrapper>
            </Content>
            <Content>
              <InputLabelWrapper>
                {i18n.t('signin-GlobalErrorBoundary-support')}
              </InputLabelWrapper>
              <AnchorWrapper href={`mailto:${email}`}>{email}</AnchorWrapper>
            </Content>
            <Content>
              <InputLabelWrapper>
                {i18n.t('signin-GlobalErrorBoundary-helpCenter')}
              </InputLabelWrapper>
              <AnchorWrapper href={helpLink} target="_blank">
                {i18n.t('signin-GlobalErrorBoundary-knowledgeBase')}
              </AnchorWrapper>
            </Content>
          </SectionWrapper>
        </ErrorWrapper>
      </ExternalScreenTemplate>
    </ThemeProvider>
  );
};

export default Error;
