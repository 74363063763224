import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useCreateCustomer,
} from 'client-lib';
import i18n from 'i18n-js';
import CreateCustomerForm from '../../components/CreateSection/CreateCustomerForm.tsx';
import {
  setActiveSidebar,
  closeCreateSectionModal,
  openSnackbar,
} from '../../actions/general';

import {
  setCreateCustomerFormValue,
  setCreateCustomer,
  clearCreateSection,
} from '../../actions/createSection';

import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const CreateCustomerFormContainer = ({ activeGroup, createSection }) => {
  const client = useApolloClient();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [
      AVAILABLE_PERMISSIONS.EDIT_CUSTOMER_REP,
      AVAILABLE_PERMISSIONS.EDIT_COMPANIES,
      AVAILABLE_PERMISSIONS.TOGGLE_PRIORITY_NOTIFICATIONS,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_LABELS,
    ],
  });

  const history = useHistory();

  const [hasInitialValues, setHasInitialValues] = useState(false);

  useEffect(() => {
    setHasInitialValues(
      !!(
        createSection.createCustomer.phoneNumber ||
        createSection.createCustomer.faxNumber
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      setCreateCustomerFormValue({
        key: 'group',
        value: {
          value: [{ label: activeGroup?.text, value: activeGroup?.value }],
        },
      })
    );
  }, []);

  const handleOnClose = (customerId, forceRoute) => {
    dispatch(setActiveSidebar('default'));
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    // if has initial phone or fax number, that means that user was on /threads or /inbox when they pushed "Create Contact" button.
    // We want them to be taken back to /thread/:id or /inbox/:id screen if this is the case, so simply closing the modal (and not rerouting) will achieve this effect. Elswise, reroute to /contacts.
    if (!hasInitialValues || forceRoute) {
      history.push(`/contacts/all/${customerId}/customercontact`);
    }
  };

  const onHandleError = (errorMessage) => {
    dispatch(openSnackbar(errorMessage, 'error'));
  };

  const editContactLabelsPermissions = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_LABELS,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const initialActiveGroup = {
    label: activeGroup?.text,
    value: activeGroup?.value,
  };

  const customerFormProps = useCreateCustomer({
    client,
    currentUser,
    handleOnClose,
    initialFormValues: createSection.createCustomer,
    onHandleError,
    activeGroup: initialActiveGroup,
    i18n,
    editContactLabelsPermissions,
  });

  return (
    <CreateCustomerForm
      handleOnClose={handleOnClose}
      customerFormProps={customerFormProps}
      dispatchSetCreateCustomer={(value) => dispatch(setCreateCustomer(value))}
      dispatchClearCreateSection={() => dispatch(clearCreateSection())}
      accountPolicies={currentUser.accountPolicies}
      groupPolicies={currentUser.groupPolicies}
    />
  );
};

CreateCustomerFormContainer.propTypes = {
  activeGroup: PropTypes.object,
  createSection: PropTypes.object.isRequired,
};

CreateCustomerFormContainer.defaultProps = {
  activeGroup: null,
};

export default CreateCustomerFormContainer;
