export type UnknownObject = {
  [key: string]: unknown;
};

/**
 * Copies the value from currentKey to addKey in the given object.
 *
 * @param obj - The object to modify.
 * @param currentKey - The key of the value to copy.
 * @param addKey - The key to copy the value to.
 * @param deleteCurrentKey - Whether to delete the current key after copying.
 * @returns - The modified object with the new key or null.
 */
const copyKey = (
  obj: UnknownObject,
  currentKey: string,
  addKey: string,
  deleteCurrentKey = false
): UnknownObject | null => {
  if (!obj || typeof obj !== 'object') {
    return null;
  }

  const objCopy = { ...obj };

  if (Object.prototype.hasOwnProperty.call(objCopy, addKey)) {
    return objCopy;
  }

  if (Object.prototype.hasOwnProperty.call(objCopy, currentKey)) {
    objCopy[addKey] = obj[currentKey];
  }

  if (deleteCurrentKey) {
    delete objCopy[currentKey];
  }

  return objCopy;
};

/**
 * Copies the value from currentKey to addKey in each object of the given array.
 *
 * @param arr - The array of objects to modify.
 * @param currentKey - The key of the value to copy.
 * @param addKey - The key to copy the value to.
 * @param deleteCurrentKey - Whether to delete the current key after copying.
 * @returns - The modified array with the new key in each object or null.
 */
const copyKeyInArray = (
  arr: UnknownObject[],
  currentKey: string,
  addKey: string,
  deleteCurrentKey = false
): (object | null)[] => {
  if (!Array.isArray(arr) || arr?.length === 0) {
    return [];
  }

  const modifiedArray = arr.map((obj) => {
    return copyKey(obj, currentKey, addKey, deleteCurrentKey);
  });

  return modifiedArray;
};

/**
 * Finds the added object in an updated object array by comparing it with an existing object array.
 *
 * @param existingObjectArray - The existing object array to compare against.
 * @param updatedObjectArray - The updated object array to search in.
 * @param comparisonKey - The key to use for comparison between objects.
 * @returns - The added object if found, otherwise null.
 */
const findAddedObject = (
  existingObjectArray: UnknownObject[],
  updatedObjectArray: UnknownObject[],
  comparisonKey: string
): UnknownObject | null => {
  if (
    !Array.isArray(existingObjectArray) ||
    !Array.isArray(updatedObjectArray) ||
    updatedObjectArray.length === 0 ||
    !comparisonKey
  ) {
    return null;
  }

  return (
    updatedObjectArray.find(
      (updatedObject) =>
        !existingObjectArray.some(
          (existingObject) =>
            updatedObject[comparisonKey] === existingObject[comparisonKey]
        )
    ) || null
  );
};

export { copyKey, copyKeyInArray, findAddedObject };
